import { render, staticRenderFns } from "./profile-certificate.vue?vue&type=template&id=0491caed&scoped=true&"
import script from "./profile-certificate.vue?vue&type=script&lang=ts&"
export * from "./profile-certificate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0491caed",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBtn,VCard,VCardText,VFlex,VHover,VIcon,VLayout,VProgressCircular})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src551672837/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0491caed')) {
      api.createRecord('0491caed', component.options)
    } else {
      api.reload('0491caed', component.options)
    }
    module.hot.accept("./profile-certificate.vue?vue&type=template&id=0491caed&scoped=true&", function () {
      api.rerender('0491caed', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/profile/partials/certificates/profile-certificate.vue"
export default component.exports